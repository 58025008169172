import axios from 'axios'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api的base_url
  timeout: 80000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
  Promise.reject(error)
})

// response拦截器
service.interceptors.response.use(
  res => {
    if (res.data.code === 503) {
      this.$toast({
        message: "服务器维护中",
      });
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export default service