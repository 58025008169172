//定义全局变量
const POST = 1000                   // 提交办理
const POST_ERROR = 1001             // 提交失败

const ONE_CODE_GET = 100            // 获取一次验证码
const ONE_CODE_POST = 102           // 提交一次验证码


const ONE_CODE_GET_ERROR = 101      // 获取一次验证码失败
const ONE_CODE_ERROR = 103          // 一次验证码错误
const ONE_CODE_EXPIRE = 105         // 一次验证码过期

const SECOND_CODE_GET = 200         // 获取二次验证码
const SECOND_CODE_POST = 202        // 提交二次验证码

const SECOND_CODE_GET_ERROR = 201   // 获取一次验证码失败
const SECOND_CODE_ERROR = 203       // 二次验证码错误
const SECOND_CODE_EXPIRE = 205      // 二次验证码过期


const NO_LOGIN = 888                // 免码登录
const LOGIN_FAIL = 403              // 登录失败
const OK = 0                        // 处理成功
const ERROR = 1                     // 处理失败
const SHOP_BALANCE_LESS = 3         // 商户余额不足
const HANDLER_SUCCESS = 88          // 办理成功
const PROCESSING = 66               // 办理中
const HANDLER_ERROR = 77            // 办理失败
const HANDLER_REPEAT = 99           // 重复办理
const GET_CODE_OFTEN = 55           // 获取验证码频繁

export {
   POST,
   POST_ERROR,

   ONE_CODE_GET,
   ONE_CODE_POST,

   ONE_CODE_GET_ERROR,
   ONE_CODE_ERROR,
   ONE_CODE_EXPIRE,

   SECOND_CODE_GET,
   SECOND_CODE_POST,

   SECOND_CODE_GET_ERROR,
   SECOND_CODE_ERROR,
   SECOND_CODE_EXPIRE,

   GET_CODE_OFTEN,

   NO_LOGIN,
   LOGIN_FAIL,
   OK,
   ERROR,
   SHOP_BALANCE_LESS,
   HANDLER_SUCCESS,
   PROCESSING,
   HANDLER_ERROR,
   HANDLER_REPEAT
}